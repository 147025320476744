<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import { VxCellDate, VxCellPlayerAvatar, VxTable } from '@/components/table'
import { passDataToResource, provablyFair } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'

export default {
  name: 'ClientSeeds',
  components: {
    VxTable,
    VxCellDate,
    VxCellPlayerAvatar
  },
  setup () {
    const resource = passDataToResource(provablyFair.clientSeeds, {
      requestParams: {
        params: { include: 'player' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        component: VxCellPlayerAvatar
      },
      { key: 'seed' },
      {
        key: 'created_at',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
